import React from 'react';

export default function Home() {
  return (
    <section className="home-intro">
      <p className="home-paragraph">WELCOME.</p>
      <p className="home-paragraph">CLICK ON THE KNOT TO LEARN MORE.</p>
    </section>
  );
}
