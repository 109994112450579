import React from 'react';
import { Canvas } from '@react-three/fiber';
import AboutBox from './AboutBox';
import AboutCone from './AboutCone';

export default function About() {
  return (
    <section className="about">
      <p className="about-info">
        After eight years of experience working in the fashion and advertising
        industries in New York City, I decided to start my own sustainable
        swimwear brand in 2019. Enter Salomé Swim, a remarkable undertaking
        aimed at crafting sustainable swimwear that not only celebrates and
        empowers women but also adheres to environmentally conscious practices.
        This entrepreneurial endeavor allowed me to put into action my
        entrepreneurial and marketing skills, visual merchandising expertise,
        and innate sense of design and aesthetics. Throughout the journey, I
        fostered collaborations with talented artists, overcame countless
        obstacles that tested my resilience, and demonstrated unwavering
        commitment.
      </p>
      <p className="about-info">
        Managing Salomé Swim proved to be a transformative experience that led
        me to the realization of{' '}
        <strong>
          my true passion: software engineering. As a thinker and a tenacious
          problem solver, I found the perfect convergence of my creative
          instincts and analytical mindset in the field of software engineering.{' '}
        </strong>
        It presents a career path abundant with infinite possibilities, allowing
        me to integrate the best of both worlds.
      </p>
      <Canvas id="canvas">
        <spotLight position={[1000, 100, 100]} intensity={4} />
        <AboutBox />
      </Canvas>
      <p className="about-info">
        <strong>
          Throughout my software engineering journey at Fullstack Academy I
          acquired extensive knowledge and skills working with JavaScript and
          its associated technologies: Node.js, Express.js, PostgreSQL, React,
          Redux and Three.js. Furthermore, I have dedicated myself to
          cultivating a profound understanding of HTML, CSS, accessibility
          standards, and the best practices in the realm of full stack web
          development. Additionally, I have familiarized myself with TypeScript
          and Next.js, broadening my toolkit and expanding my capabilities.
        </strong>
      </p>
      <p className="about-info">
        While I take pride in my achievements thus far, what truly ignites my
        passion is the prospect of continued learning and professional growth. I
        eagerly anticipate the opportunity to immerse myself in new
        technologies, absorb fresh perspectives, and push the boundaries of my
        skill set. With each new challenge, I embrace the chance to enhance my
        proficiency and contribute to the ever-evolving field of web
        development.
      </p>
      <Canvas id="canvas">
        <spotLight position={[100, 1000, 100]} intensity={4} />
        <AboutCone />
      </Canvas>
      <p className="about-info">
        The core principles that guided my business, namely sustainability,
        representation of underrepresented communities, and mental health
        awareness, continue to be my driving force as I embark on my software
        engineering journey. It is my intention to infuse these ideals into my
        work, utilizing technology as a catalyst for positive change and
        inclusive innovation.
      </p>
    </section>
  );
}
