import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import lisaQuestImage from '../../public/stars.png';
import allMatchaImage from '../../public/matcha.png';
import roomImage from '../../public/room.png';
import earthPlusProject from '../../public/earthplusproject.png';

export default function Projects() {
  const [projectContainer, setProjectContainer] = useState('');
  const isMobile = window.innerWidth <= 768 && window.innerHeight <= 1024;

  const projects = [
    {
      id: 'earth',
      link: 'earth-plus',
      image: earthPlusProject,
      imageAlt: 'close-up of an earth globe',
      containerClassName: 'earth-container',
      headline: 'EARTH+',
      description: 'Earth appreciation web application',
      ariaLabel:
        'Earth+. Earth appreciation web application. Click to see project page.',
    },
    {
      id: 'lisa',
      link: 'lisa-quest',
      image: lisaQuestImage,
      imageAlt: 'stars illustration',
      containerClassName: 'lisa-container',
      headline: 'LISA QUEST',
      description: 'Classic arcade style game',
      ariaLabel:
        'Lisa Quest. Classic arcade style game. Click to see project page.',
    },
    {
      id: 'matcha',
      link: 'all-matcha',
      image: allMatchaImage,
      imageAlt: 'Pink and green gradiant',
      containerClassName: 'matcha-container',
      headline: 'ALL MATCHA',
      description: 'Fictional e-commerce website',
      ariaLabel:
        'All matcha. Fictional e-commerce website. Click to see project page.',
    },
    {
      id: 'room',
      link: 'room',
      image: roomImage,
      imageAlt: 'Globe with texture on a black background',
      containerClassName: 'room-container',
      headline: 'ROOM',
      description: 'Experiment with Three.js',
      ariaLabel: 'Room. Experiment with Three.js. Click to see project page.',
    },
  ];

  useEffect(() => {
    const handleMouseOver = (evt) => setProjectContainer(evt.target.className);
    const handleMouseOut = () => setProjectContainer('');

    const projectsImages = document.querySelector('#projects-images');
    projectsImages.addEventListener('mouseover', handleMouseOver);
    projectsImages.addEventListener('mouseout', handleMouseOut);

    return () => {
      projectsImages.removeEventListener('mouseover', handleMouseOver);
      projectsImages.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  return (
    <section
      id="projects-images"
      aria-label="click to learn more about these projects."
    >
      <div>
        {projects.map((project) => {
          return (
            <div key={project.id}>
              <Link to={project.link} className="images-containers">
                <img
                  src={project.image}
                  className={project.containerClassName}
                  alt={project.imageAlt}
                />
              </Link>
              {isMobile ? <ProjectsInfo {...project} /> : null}
              {projectContainer === project.containerClassName && !isMobile ? (
                <ProjectsInfo {...project} />
              ) : null}
            </div>
          );
        })}
        <Outlet />
      </div>
    </section>
  );
}

const ProjectsInfo = ({
  id,
  link,
  containerClassName,
  headline,
  description,
  ariaLabel,
}) => {
  return (
    <Link to={link}>
      <div id={id} className={containerClassName} aria-label={ariaLabel}>
        <h2 className={containerClassName}>{headline}</h2>
        <p className={containerClassName}>{description}</p>
      </div>
    </Link>
  );
};
