import React from 'react';

export default function NotFound() {
  return (
    <section className="not-found">
      <h1 className="not-found-info">
        THE PAGE YOU ARE TRYING TO ACCESS DOES NOT EXIST.
      </h1>
      <h2 className="not-found-info">
        CLICK ON THE KNOT TO GET BACK ON TRACK.
      </h2>
    </section>
  );
}
