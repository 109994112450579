import React from 'react';
import earthVideo from '../../public/earth-plus/earthPlus-video.mov';
import earthPage from '../../public/earth-plus/earthPlus-page.png';
import apod from '../../public/earth-plus/earthPlus-apod.png';
import earthSelectForm from '../../public/earth-plus/earthPlus-selectForm.png';

export default function EarthPlus() {
  return (
    <main className="project-container">
      <section className="project-contributions">
        <h1>EARTH+</h1>
        <h2 className="project-summary">
          Web application created to celebrate earth and share important climate
          information.
        </h2>
        <h2 className="project-deployed-link">
          Visit{' '}
          <a
            href="https://earthplus.onrender.com/"
            target="_blank"
            aria-label="Link to deployed project website"
          >
            Earth+
          </a>{' '}
          website to explore.
        </h2>

        <video
          src={earthVideo}
          className="section-video"
          autoplay="true"
          loop="true"
          alt="3D rendering of planet Earth animated to loop left to right."
        />

        <p>
          Earth+ is a dynamic web application crafted with the powerful
          combination of Node.js, Express, React, and Three.js. I started this
          project at the beginning of my development journey as an opportunity
          to practice my newfound skills. Over time, Earth+ has evolved into a
          completely redesigned version that takes advantage of the immense
          potential of external APIs, allowing users to access valuable data.
        </p>
      </section>

      <section className="project-contributions">
        <img
          src={apod}
          className="section-image"
          loading="lazy"
          alt="Astronomy Picture Of the Day. What's that near the Moon? It's the International Space Station (ISS). Although the ISS may appear to be physically near the Moon, it is not - it is physically near the Earth. In low Earth orbit and circulating around our big blue marble about every 90 minutes, the ISS was captured photographically as it crossed nearly in front of the Moon. The Moon, itself in a month-long orbit around the Earth, shows a crescent phase as only a curving sliver of its Sun-illuminated half is visible from the Earth. The featured image was taken in late March from Shanghai, China and shows not only details of Earth's largest human-made satellite, but details of the cratered and barren surface of Earth's largest natural satellite. Over the next few years, humanity is planning to send more people and machines to the Moon than ever before"
        ></img>
        <p>
          Discover the captivating wonders of the cosmos with Earth+, where you
          are invited to embark on an extraordinary journey through NASA's
          awe-inspiring Astronomy Picture of the Day. Experience the convenience
          of selecting any date that piques your curiosity, as the platform
          grants you access to a mesmerizing collection of celestial marvels
          that adorn our vast universe.
        </p>
        <img
          src={earthSelectForm}
          className="section-image"
          loading="lazy"
          alt="Select date form showcasing a date selector and an enter button."
        ></img>
        <p>
          To bring you this celestial showcase, Earth+ seamlessly retrieves data
          from NASA's robust API. By leveraging this powerful integration,
          Earth+ harnesses the immense wealth of knowledge and captivating
          imagery provided by NASA. This ensures that your visual experience is
          constantly evolving and dynamic.
        </p>
      </section>
      <section className="project-contributions">
        <img
          src={earthPage}
          className="section-image"
          alt="Earth page of the Earth+ application. In the middle of the page, there is a black box with a 3D rendering of the planet in constant animation. To the right of that, there is text that reads 'Climate Data', and below 'Select a Climate concern to learn more'. Below the text there is a dropdown menu to select a concern."
        />
        <p>
          To create the 3D rendering of Earth, I utilized React Three Fiber and
          Three.js. Additionally, with the integration of a specialized Rapid
          API dedicated to climate change, I am able to obtain comprehensive
          climate data about several climate concerns, as well as their sources
          for more information.
        </p>
        <p>
          Earth+ is an ever evolving project. I am constantly working to improve
          the platform, as well as to expand its capabilities and features.
        </p>
      </section>
    </main>
  );
}
